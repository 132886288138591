import React, { useState, useCallback } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
var funccount = new Set();

const NotFoundPage = () => {
  const [count, setCount] = useState(0)
  const [number, setNumber] = useState(0)
 
const incrementCounter = useCallback(() => {
  setCount(count + 1)
}, [count])
const decrementCounter = useCallback(() => {
  setCount(count - 1)
}, [count])
const incrementNumber = useCallback(() => {
  setNumber(number + 1)
}, [number])
   
funccount.add(incrementCounter);
funccount.add(decrementCounter);
funccount.add(incrementNumber);
 return <Layout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <div>
    Size:{funccount.size}
      Count: {count}
      <button onClick={incrementCounter}>
         Increase counter
      </button>
      <button onClick={decrementCounter}>
         Decrease Counter
      </button>
      <button onClick={incrementNumber}>
         increase number
      </button>
    </div>
  </Layout>
};

export default NotFoundPage;
